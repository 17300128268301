.Header {
  background-color: #f4f7f9;
  width: 100%;
  height: 80px;
  padding: 20px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
}

.NavController {
  display: flex;
  align-items: center;

  div {
    height: 40px;
    width: 40px;
    border: 2px solid #dbdbdb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #136f86;
    }
  }

  span {
    margin-inline-start: 1rem;
    font-weight: 500;
    font-size: 14px;
    color: #868686;
  }
}

.LogoContainer {
  a {
    display: inline-flex;
    text-decoration: none;
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;

    span {
      width: 10rem;
      overflow: hidden;
      height: 2.5rem;
      position: relative;

      div {
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        margin: 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          box-sizing: border-box;
          padding: 0;
          border: none;
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .Header {
    padding-left: 15px;
    padding-right: 15px;
  }
}
