.Backdrop {
  width: 100%;
  height: 100px;
}

.BackdropImage {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
