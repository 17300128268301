.Form {
  width: 100%;
  height: 100vh;
  background-color: #f4f7f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 60px;
}

.Header {
  h1 {
    color: #136f86;
    font-weight: 700;
    font-size: 30px;
  }
}

.FormContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FormWrapper {
  padding: 40px;
  // width: 50%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.FormFields {
  display: flex;
  gap: 2rem;
}

.FormHeader {
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 1rem;
    color: black;
    font-weight: 500;
    font-size: 16px;
  }

  input {
    padding: 17px;
    border: 1px solid #bcd0e8;
    border-radius: 8px;
    margin-bottom: 1rem;
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 15px;
    color: #808080;
    font-family: "Inter", sans-serif;
    &::placeholder {
      font-weight: 500;
      font-size: 15px;
      color: #808080;
    }
  }
}

.FormBody {
  // position: absolute;
}

.AmountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(270deg, #5ecae4 0%, #168eb4 100%);
  box-shadow: 0px 4px 10px rgba(65, 180, 211, 0.46);
  border-radius: 10px;
  padding: 20px 27px;
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
  span {
    margin-bottom: 1rem;
    color: black;
    font-weight: 500;
    font-size: 16px;
    color: white;
  }
  input {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    width: 100%;
    background-color: transparent;
    padding: 10px 17px;
    font-weight: 700;
    font-size: 22px;
    outline: none;
    caret-color: white;
    color: white;
    font-family: "Inter", sans-serif;
  }
}

.ReferenceSection {
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 1rem;
    color: black;
    font-weight: 500;
    font-size: 16px;
  }
  input {
    padding: 17px;
    border: 1px solid #bcd0e8;
    border-radius: 8px;
    margin-bottom: 1rem;
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 15px;
    color: #808080;
    &::placeholder {
      font-weight: 500;
      font-size: 15px;
      color: #808080;
    }
  }
  textarea {
    padding: 17px;
    border: 1px solid #bcd0e8;
    border-radius: 8px;
    margin-bottom: 1rem;
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 15px;
    color: #808080;
    resize: none;
    font-family: "Inter", sans-serif;
    &::placeholder {
      font-weight: 500;
      font-size: 15px;
      color: #808080;
      font-family: "Inter", sans-serif;
    }
  }
}

.ButtonContainer {
  margin-top: 60px;
  background-color: white;
  width: 100%;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  position: fixed;
  bottom: 0;
  button {
    width: 20%;
    background-color: #136f86;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 17px;
    border: none;
    outline: none;
    border-radius: 7px;
    color: white;
    box-shadow: 0px 4px 8px rgba(151, 187, 199, 0.5);
  }
}

.Footer {
  font-weight: 500;
  font-size: 12px;
  margin-top: 20px;
  color: #868686;
}

@media (max-width: 1024px) {
  // .FormWrapper {
  //   width: 65%;
  // }
}

@media (max-width: 768px) {
  .FormContainer {
    width: 100%;
  }

  // .FormWrapper {
  //   width: 80%;
  // }
}

@media (max-width: 425px) {
  // .FormWrapper {
  //   width: 90%;
  // }
  .FormFields {
    flex-direction: column;
    gap: 0;
  }
  .FormWrapper {
    padding: 30px;
  }
  .FormContainer {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .Form {
    height: auto;
  }
  .ButtonContainer {
    button {
      width: 90%;
    }
  }
}
